import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Toast from 'vue-toastification'

import msalPlugin from './plugins/msalPlugin';

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-toastification/dist/index.css';

require('./assets/css/cui-basic.min.css')
require('./assets/css/cui-standard.min.css')
require('./assets/css/cui-styleguide.min.css')
require('./assets/css/cui-login.css')

import vuetify from './plugins/vuetify'

/*
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
}) */



// import VueMeta from 'vue-meta'
//import CKEditor from '@mayasabha/ckeditor4-vue3'
import PrimeVue from 'primevue/config';
import Editor from 'primevue/editor'; 
import Aura from '@primevue/themes/aura';

//import Vuetify from 'vuetify/lib'
//const vuetify = new Vuetify({})




const app = createApp(App)

//app.config.globalProperties.$msalInstance = {}

app.use(msalPlugin);
app.use(store)
app.use(router)
app.use(vuetify)
//app.use(CKEditor)
app.use(Toast)
// app.use(VueMeta)
app.use(PrimeVue, {
  csp: {
    nonce: 'FrFBJXRJMyWXjAjg4cUNZwEKktzfzD/YD9+S1kj2ors67hKoveam4aL0bZuCZU/jTiHTn0xDQGQh2ksCMXTXtA=='
  },
  theme: {
    preset: Aura,
    options: {
        prefix: 'p',
        darkModeSelector: 'system',
        cssLayer: false
    }
  }
});
app.component('Editor', Editor);
app.mount('#app')
