<template>
  <div id="viewMaint" class="bg">
        <div class="text-center">
            <br/><br/>
            <div class="cui">
              <v-chip
                  class="ma-2 text-bold text-medium"
                  outlined
                  >
                  SL1 Tenant Maintenance Overview
              </v-chip>
            </div><br/><br/>
              <v-spacer></v-spacer>
              <div  class="ttec_contentWrapper pa-2 ml-0 mr-0 pr-0">
              <v-row no-gutters>
                <v-text-field
                        class="custom-text-field mb-5 ml-8 mr-9 pt-2 mt-1"
                        v-model="search"
                        append-icon="mdi-magnify"
                        density="compact"
                        single-line
                        placeholder="Search by company, description, Summary, or Root Status"
                        hide-details
                    >
                 </v-text-field>
                 <v-btn :disabled="selectedMaints.length == 0"  rounded class="text-bold-ttec ciscoblue mr-5 mt-3 ml-8" @click.stop="termMaint()">Terminate Selected Maintenance</v-btn>
              </v-row>
                    <v-data-table 
                      :headers="header_maintenances"
                      :items="maintenancesEntries"
                      :items-per-page="-1"
                      item-value="maint_id"
                      v-model="selectedMaints"
                      class="elevation-6 ml-2 mr-2 mt-0"
                      :search="search"
                      :loading="loadMaintList"
                      loading-text="Loading ..."
                      show-select
                      density="compact"
                      :footer-props="{
                          'items-per-page-options': [50, 100, 300, -1],
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right'
                        }"
                      >
                      <template v-slot:[`item.status`]="{ item }">
                        <div v-if="item.status == 'InProgress'" class ="maint_health">{{ item.status }}</div>
                        <div v-else-if="item.status == 'Pending'" class ="maint_warning">{{ item.status }}</div>
                        <div v-else-if="item.status ==='Expired'" class ="maint_expired">{{ item.status }}</div>
                      </template>
                    </v-data-table>
                    <br/><br/>
              </div>
              <v-snackbar
                        v-model="hasSaved"
                        :timeout="3000"
                        attach
                        position="absolute"
                        location="bottom left"
                        >
                        {{ this.snackbar_msg }}
              </v-snackbar>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'MaintenanceView',
  data: () => ({
    header_maintenances: [],
    maintenancesEntries: [],
    selectedMaints: [],
    loadMaintList: false,
    search: '',
    snackbar_msg: 'Record has been updated',
    hasSaved: false,
    queryData: { 'matrix': 'maintenanceList' }
  }),

  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      this.loadMaintList = true
      axios.post(`${API_URL}/api/sl1/maintences_pull`, this.queryData, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          this.loadMaintList = false
          var retObj = payload.data
          this.maintenancesEntries = retObj.maintenances
          this.header_maintenances = [
            { title: 'Company',  key: 'company', align: 'start'},
            { title: 'Did', key: 'did', align: 'start' },
            { title: 'Device', key: 'device', align: 'start' },
            { title: 'Start (UTC)', key: 'start', align: 'start' },
            { title: 'End (UTC)', key: 'end', align: 'start' },
            { title: 'Description', key: 'desc', align: 'start' },
            { title: 'Summary', key: 'summary', align: 'start' },
            { title: 'MaintID', key: 'maint_id', align: 'start' },
            { title: 'Status', key: 'status', align: 'start' }
          ]
        })
    })
    this.loadMaintList = false
  },
  created () {
  },

  watch: {
  },
  methods: {
    termMaint () {
      var API_URL = 'http://xx.xx.xx.xx:xxxx'
      fetch('/config.json').then(res => res.json()).then(config => {
        API_URL = config['API_URL']
        var query = {
          'maint_list': this.selectedMaints
        }
        axios.post(`${API_URL}/api/sl1/terminate_maintenace`, query, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            var retObj = payload.data
            var status = retObj.status
            // alert(status)
            if (status === 'ok') {
              const toast = useToast()
              toast.success('Record had been updated.')
              this.loadMaintList = true
              axios.post(`${API_URL}/api/sl1/maintences_pull`, this.queryData, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
                (payload) => {
                  this.loadMaintList = false
                  var retObj = payload.data
                  this.maintenancesEntries = retObj.maintenances
                  this.header_maintenances = [
                    { title: 'Company',  key: 'company', align: 'start'},
                    { title: 'Did', key: 'did', align: 'start' },
                    { title: 'Device', key: 'device', align: 'start' },
                    { title: 'Start (UTC)', key: 'start', align: 'start' },
                    { title: 'End (UTC)', key: 'end', align: 'start' },
                    { title: 'Description', key: 'desc', align: 'start' },
                    { title: 'Summary', key: 'summary', align: 'start' },
                    { title: 'MaintID', key: 'maint_id', align: 'start' },
                    { title: 'Status', key: 'status', align: 'start' }
                  ]
                })
              this.loadMaintList = false
            }
          })
      })
    }
  }
}
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 150%;
        position: absolute;
        top: 0;
        left: 0;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .select {
      max-width: 60px;
      max-height: 60px;
      font-size: 11px;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:100%;
        height:100%;
        overflow:hidden;
        background-color:#eeeeee; /* for IE7 #fff  #bbaadd before 00bceb */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:1px;
        margin-left:3px;
        padding-left: 3px;
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .v-text-field :deep(input) {
        font-size: 0.9em;
        font-weight: 300;
        padding: 0px;
        margin: 0px;
        /* text-transform: capitalize; */
    }
    .v-text-field :deep(label) {
        font-size: 0.9em;
    }
    .v-text-field :deep(button) {
        font-size: 0.8em;
    }
    .v-input :deep(control) {
      height:10px !important
    }
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      text-align: left;
      padding: 0px;
      font-weight: 300;
    }
    .maint_health {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .maint_warning {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .maint_expired {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #808080;
      font-weight:bold;
      background: #808080;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .custom-text-field :deep(.v-field__input) { 
      padding-top: 0; 
      padding-block: 0; 
      margin: 0;
      height: 8px !important;
      font-size: 14px;
      }

      .text-bold-ttec {
        font-weight: bold
      }
</style>
